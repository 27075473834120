import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CircularProgress, Icon } from '@mui/material'

import { mutations, queries } from '../../../graphql'
import {
  Acknowledge,
  Button,
  CardBox,
  Container,
  Dialog,
  Fields,
  InputAdornment,
  LinearProgress,
  Stack,
  Scheduled,
  Recurring,
  useAlert,
  useDialog,
  validations,
  Iframe,
  Text,
  Icons,
  Row,
} from '../../../components'
import * as Bank from '../../../components/payment/bank-transfer'
import { fromAmountField } from '../../../utils'

export function BankTransferZum() {
  const { data: { getZumrailsToken: addPaymentProfileToken } = {}, refetch: refetchZumrailsToken } = useQuery(
    queries.paymentMethods.getZumrailsToken
  )
  const {
    data: { userHasBankAccount } = {},
    refetch: refetchUserHasBankAccount,
    loading: userHasBankAccountLoading,
  } = useQuery(queries.paymentMethods.userHasBankAccount, {
    notifyOnNetworkStatusChange: true,
  })
  const [createZumRailsUser] = useMutation(mutations.paymentMethods.createZumRailsUser)

  const loadIframe = () => {
    const token = addPaymentProfileToken

    // eslint-disable-next-line no-undef
    ZumRailsSDK.init({
      token,
      onLoad: () => {
        console.log('onLoad')
      },
      onError: (error) => {
        console.log('onError', error)
      },
      onSuccess: (data) => {
        console.log('onSuccess', data)
        createZumRailsUser({
          variables: {
            data: {
              id: data.userId,
            },
          },
        })
        refetchUserHasBankAccount()
      },
      onButtonClose: () => {
        console.log('onButtonClose')
        refetchZumrailsToken()
      },
    })
  }

  const content = () => {
    if (userHasBankAccountLoading || userHasBankAccount === undefined) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <CircularProgress color="info" />
          <div
            style={{
              marginLeft: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text.Body>One moment...</Text.Body>
          </div>
        </div>
      )
    }
    if (userHasBankAccount === true) {
      return <BankTransferForm />
    }
    return (
      <>
        <Text.Body>To connect your online bank account, please click the button below.</Text.Body>
        <Button onClick={loadIframe}>Connect Bank Account</Button>
      </>
    )
  }

  return (
    <Container maxWidth="lg">
      <CardBox title="Bank Transfer">
        <Stack spacing={4}>
          <Bank.FAQ />
          {content()}
        </Stack>
      </CardBox>
    </Container>
  )
}

function BankTransferForm({ setIsNewBankAccount, linkedBanks = [] }) {
  const { fundId } = useParams()
  const { data: { me: { fund } = { fund: {} } } = {} } = useQuery(queries.funds.myFund)
  const [{ Alert, alertProps }, { setAlert }] = useAlert()
  const { data: { linkedZumRailsBanks = [] } = {}, loading: loadingZumRailsBanks } = useQuery(
    queries.paymentMethods.linkedZumRailsBanks
  )
  const [{ dialogProps }, { open: openDialog, close: closeDialog }] = useDialog()
  const {
    control,
    register,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset: resetForm,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      amount: 0,
      isRecurring: false,
      recurringInterval: 'monthly',
    },
  })
  const closeSuccessDialog = () => {
    closeDialog()
    resetForm()
  }
  const [createContribution, { data, loading }] = useMutation(mutations.contributions.createContribution, {
    refetchQueries: [
      // { query: queries.funds.myFund, variables: { fundId: Number(fundId) } },
      // { query: queries.funds.myFundActivity, variables: { fundId: Number(fundId) } },
      // { query: queries.paymentMethods.myLinkedBanks },
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      openDialog()
    },
    onError: (e) => {
      setAlert({
        message: `Failed to create a contribution: ${e.message ?? ''}`,
        error: e.message,
        severity: 'error',
      })
      resetForm()
    },
  })
  const { errors = {} } = formState
  const onSubmit = async ({ amount, isRecurring, recurringInterval, processingDate }) => {
    const contributionObj = {
      paymentMethodId: Number(linkedZumRailsBanks?.[0]?.PaymentMethodId),
      fundId: Number(fund.id),
      amount: fromAmountField(amount),
      isRecurring,
      recurringInterval,
      processingDate,
      processor: 'zumrails',
    }
    console.log('contributionObj', contributionObj)
    createContribution({
      variables: {
        data: contributionObj,
      },
    })
  }
  console.log(fund)
  const { Institution, AccountNumber } = linkedZumRailsBanks.length > 0 ? linkedZumRailsBanks[0] : {}

  const bankAccountInfo = () => {
    const loaderOrInfo = () => {
      if (loadingZumRailsBanks) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '8px',
            }}
          >
            <CircularProgress size={16} color="info" />
          </div>
        )
      }

      return (
        <Text.Body style={{ marginLeft: '8px', color: 'grey' }}>
          {`${Institution} - **** ${AccountNumber?.slice(-4)}`}
        </Text.Body>
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '8px',
        }}
      >
        <Icons.Account />
        <Text.Body style={{ marginLeft: '8px' }}>Bank Account</Text.Body>
        {loaderOrInfo()}
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2}>
          <Fields.Text
            label="Amount"
            name="amount"
            error={!!errors?.amount}
            helperText={errors?.amount?.message}
            InputProps={{
              inputComponent: Fields.Amount,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                setValue: (value) => setValue('amount', value),
                getValue: () => getValues('amount'),
                defaultValue: 0,
              },
            }}
            {...register('amount', {
              ...{ min: { value: 1, message: 'Must be at least 1' } },
              ...validations.required,
              ...validations.number,
            })}
          />
          {bankAccountInfo()}
          {/* <Bank.SavedAccounts register={register} linkedBanks={linkedBanks} setIsNewBankAccount={setIsNewBankAccount} /> */}
          <Scheduled register={register} errors={errors} control={control} getValues={getValues} setValue={setValue} />
          <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
          <Button type="submit" sx={{ alignSelf: 'flex-end' }}>
            Submit
          </Button>
          <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
        </Stack>
      </form>
      <Dialog {...dialogProps} onClose={closeSuccessDialog}>
        <Acknowledge.Success title="Success!" content="You have successfully created a contribution.">
          <Button to={`/funds/${fund.id}/activity/giving-wallet`}>Done</Button>
        </Acknowledge.Success>
      </Dialog>
    </>
  )
}
