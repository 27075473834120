import { useLazyQuery } from '@apollo/client'
import { Box, Alert as MuiAlert, AlertTitle as MuiAlertTitle } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import {
  Button,
  Checkbox,
  Container,
  ContentBox,
  Fields,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icons,
  InputAdornment,
  InputLabel,
  MenuItem,
  Recurring,
  Row,
  Scheduled,
  Select,
  Stack,
  Text,
  Tooltip,
  useAlert,
  validations,
} from '../../components'
import { useLoginDialog } from '../../components/login/useLoginDialog'
import * as SecuritiesPayment from '../../components/payment/securities'
import { queries } from '../../graphql'
import { useAuth } from '../../hooks'
import palette from '../../palette.json'
import { userName } from '../../utils'
import { calculateTipAmount } from './gift-details-math'
import { PaymentMethodsMeta } from './payment'
import { PaymentMethodRadioList } from './PaymentMethodRadioList'

export function GiftAmountStep({ isLoggedIn, charity, me, defaultFund, stepperState, dispatchStep }) {
  const navigate = useNavigate()

  const { register, handleSubmit, formState, getValues, setValue, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      paymentMethodType: stepperState?.payment?.paymentMethodType ?? '',
      // Gift
      amount: stepperState?.giftDetails?.amount ?? '0',
      securities: stepperState?.giftDetails?.securities ?? SecuritiesPayment.defaultSecurities,
      addTip: stepperState?.giftDetails?.addTip ?? true,
      customTip: stepperState?.giftDetails?.customTip ?? '',
      isRecurring: stepperState?.giftDetails?.isRecurring ?? false,
      recurringInterval: stepperState?.giftDetails?.recurringInterval || 'monthly',
      purposeOfGrant: stepperState?.giftDetails?.purposeOfGrant ?? '',
      purposeOfGrantNote: stepperState?.giftDetails?.purposeOfGrantNote ?? '',
      processingDate: stepperState?.giftDetails?.processingDate ?? undefined,
      selfAttestation: stepperState?.giftDetails?.selfAttestation ?? false,
    },
  })
  const { isSubmitting, errors = {} } = formState

  const [{ Alert, alertProps }, { setAlert }] = useAlert()

  const [, { logout }] = useAuth()
  const { loginDialog, startLogin } = useLoginDialog()

  const { profile, email: primaryEmail, userEmails } = me ?? {}
  const profileName = userName(profile)

  const [canGiveAsGuest, { data: { canGiveAsGuest: canUseEmail } = {} }] = useLazyQuery(queries.funds.canGiveAsGuest)

  const checkEmailUse = () => {
    const email = getValues('email')
    canGiveAsGuest({
      variables: { email },
    })
  }

  const watchProcessingDate = watch('processingDate')
  const isScheduledGrant = !!watchProcessingDate

  const watchedAmount = watch('amount')
  const isAddTipOpen = watch('addTip')

  let purposeOfGrantNoteLabel = ''
  switch (getValues('purposeOfGrant')) {
    case 'Fundraising Support':
      purposeOfGrantNoteLabel = 'Name of fundraising project'
      break
    case 'In memory of':
      purposeOfGrantNoteLabel = 'Full Name'
      break
    case 'Other':
      purposeOfGrantNoteLabel = 'Please describe the reason for your gift'
      break
    case 'Thanks for the work you do!':
    default:
      purposeOfGrantNoteLabel = 'Additional note'
      break
  }

  function reorderArrayByReason(array, reasons) {
    const specificOrder = reasons.map((reason) => array.find((item) => item.reason === reason))
    const filteredArray = array.filter((item) => !reasons.includes(item.reason))
    return specificOrder.concat(filteredArray)
  }

  const purposeOfGiftDefaults = ['General Support', 'In Memory Of', 'Other']

  const purposeOfGiftOptions = () => {
    if (charity?.purposeOfGifts && charity.purposeOfGifts.length) {
      return reorderArrayByReason(charity.purposeOfGifts, purposeOfGiftDefaults)
        .filter((p) => p.active)
        .map((p) => (
          <MenuItem key={p.reason} value={`${p.reason}`}>
            {p.reason}
          </MenuItem>
        ))
    }
    return purposeOfGiftDefaults.map((p) => <MenuItem value={`${p.reason}`}>{p.reason}</MenuItem>)
  }

  const location = useLocation()
  const onSubmit = async ({
    email,
    paymentMethodType,
    // Gift Amount
    amount,
    securities,
    addTip,
    customTip,
    purposeOfGrant,
    purposeOfGrantNote,
    recurringInterval,
    isRecurring,
    processingDate,
    selfAttestation,
    isGuest,
  }) => {
    const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}

    const loginFirst = (cb) => {
      if (isLoggedIn || canUseEmail) {
        cb()
      } else {
        startLogin({
          prefillEmail: email,
          onCompleted: () => {
            cb()
          },
        })
      }
    }

    dispatchStep({
      state: {
        isGuest,
        email,
        giftDetails: {
          ...(PaymentMethodMeta.amountType === 'securities'
            ? {
                amountType: 'securities',
                amount: 0,
                securities,
                addTip: false,
                customTip: '',
              }
            : {
                amountType: 'amount',
                amount,
                securities: [],
                addTip,
                customTip,
              }),
          purposeOfGrant,
          purposeOfGrantNote,
          recurringInterval,
          isRecurring: PaymentMethodMeta.supportsRecurring === false ? false : isRecurring,
          processingDate: PaymentMethodMeta.supportsScheduled === false ? false : processingDate,
          selfAttestation,
        },
        payment: { paymentMethodType },
      },
    })

    if (isGuest) {
      dispatchStep({ action: 'NEXT' })
    } else {
      await loginFirst(() => {
        dispatchStep({ action: 'NEXT' })
      })
    }
  }

  const paymentMethodType = watch('paymentMethodType')
  const PaymentMethodMeta = PaymentMethodsMeta[paymentMethodType] ?? {}

  const [tipAmount, setTipAmount] = useState(0)
  useEffect(() => {
    setTipAmount(
      calculateTipAmount({
        amountType: PaymentMethodMeta.amountType ?? 'amount',
        ...getValues(),
      })
    )

    const subscription = watch((values) => {
      setTipAmount(
        calculateTipAmount({
          amountType: PaymentMethodMeta.amountType ?? 'amount',
          ...values,
        })
      )
    })

    return () => subscription.unsubscribe()
  }, [PaymentMethodMeta.amountType, getValues, watch])

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ display: 'contents', border: 0, p: 0, m: 0 }}>
          <Stack spacing={2}>
            {isLoggedIn ? (
              <MuiAlert severity="info">
                <MuiAlertTitle>Welcome back, {profileName}</MuiAlertTitle>
                We will auto-fill donor info from your user account. You&apos;ll need to{' '}
                <Link
                  to="/logout"
                  onClick={(e) => {
                    logout({ skipNavigation: true })
                    e.preventDefault()
                  }}
                >
                  logout
                </Link>{' '}
                to make a donation under a different name.
              </MuiAlert>
            ) : (
              <ContentBox border>
                <Stack>
                  <Fields.Text
                    label="Email"
                    required
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...register('email', { ...validations.required, onBlur: checkEmailUse })}
                  />
                </Stack>
                {canUseEmail === false && (
                  <MuiAlert severity="warning" sx={{ mt: 2 }}>
                    <MuiAlertTitle>Welcome back</MuiAlertTitle>
                    Your donor information is protected by your user account. You&apos;ll need to{' '}
                    <Link
                      to="/login"
                      state={{ afterLogin: location.pathname }}
                      onClick={(e) => {
                        startLogin({
                          prefillEmail: getValues('email'),
                          onCompleted: () => {
                            // @note the useAuth does share its state so we have to make a fake navigation to re-render with the updated login state
                            navigate('.')
                          },
                        })
                        e.preventDefault()
                      }}
                    >
                      log in with this email
                    </Link>{' '}
                    to make a donation.
                  </MuiAlert>
                )}
              </ContentBox>
            )}
            <ContentBox border>
              <Stack spacing={2}>
                <PaymentMethodRadioList
                  control={control}
                  setValue={setValue}
                  fund={defaultFund}
                  hideRadio={!paymentMethodType}
                  isGuest={!isLoggedIn}
                />
                {PaymentMethodMeta.amountType === 'securities' ? (
                  <SecuritiesPayment.SecuritiesToTransferInKind required {...{ register, control, formState }} />
                ) : (
                  <>
                    <Text.H6>Gift Details *</Text.H6>
                    <Controller
                      control={control}
                      name="amount"
                      rules={{
                        required: 'This field is required',
                        // positive: (val) => parseFloat(val) > 0 || 'Must be a positive number',
                        min: { value: 1, message: 'Must be at least 1' },
                      }}
                      render={({ field, fieldState }) => (
                        <Fields.Text
                          {...field}
                          label="Amount"
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            inputComponent: Fields.Amount,
                          }}
                        />
                      )}
                    />
                  </>
                )}
                {isLoggedIn && (
                  <>
                    {PaymentMethodMeta.supportsRecurring !== false && (
                      <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
                    )}
                    {PaymentMethodMeta.supportsScheduled !== false && (
                      <Scheduled
                        register={register}
                        errors={errors}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        entity="Gift"
                      />
                    )}
                  </>
                )}
                {PaymentMethodMeta.amountType !== 'securities' && (
                  <Stack spacing={3}>
                    <Controller
                      control={control}
                      name="addTip"
                      render={({ field }) => (
                        <Row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                value="yes"
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked)
                                }}
                              />
                            }
                            label="I want to help cover GiveWise’s transaction fees."
                          />
                          <Tooltip title="Despite our efficient digital processes that keep transaction costs low, there are still fees for money movement. Your Courtesy Payment is appreciated">
                            {/* <Text.Body> */}
                            <Icons.Help />
                            {/* </Text.Body> */}
                          </Tooltip>
                        </Row>
                      )}
                    />
                    {isAddTipOpen && (
                      <Fields.Text
                        label="Courtesy Payment"
                        name="customTip"
                        placeholder={tipAmount.toFixed(2)}
                        error={!!errors?.customTip}
                        helperText={errors?.customTip?.message}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          sx: {
                            '::placeholder': {
                              color: '#A9A9A9', // Dark Grey
                            },
                          },
                        }}
                        {...register('customTip', {
                          ...validations.number,
                        })}
                      />
                    )}
                  </Stack>
                )}
                {PaymentMethodMeta.supportsRecurring !== false && (
                  <Recurring register={register} errors={errors} getValues={getValues} setValue={setValue} />
                )}
                {PaymentMethodMeta.supportsScheduled !== false && (
                  <Scheduled
                    register={register}
                    errors={errors}
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    entity="Gift"
                  />
                )}
                <div
                  style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: 'darkgrey',
                    marginTop: '24px',
                  }}
                />
                <Text.Body>Purpose of Gift</Text.Body>
                <Stack>
                  <FormControl error={!!errors?.purposeOfGrant} fullWidth>
                    <InputLabel id="purposeOfGrantLabel">Please select the gift designation</InputLabel>
                    <Select
                      value={getValues('purposeOfGrant')}
                      aria-label="Please select the gift designation"
                      labelId="purposeOfGrantLabel"
                      label="Please select the gift designation"
                      {...register('purposeOfGrant')}
                      onChange={(e) => {
                        setValue('purposeOfGrant', e.target.value, { shouldValidate: true })
                      }}
                    >
                      {purposeOfGiftOptions()}
                    </Select>
                    {errors?.purposeOfGrant && <FormHelperText>{errors?.purposeOfGrant?.message}</FormHelperText>}
                  </FormControl>
                  {getValues('purposeOfGrant') && getValues('purposeOfGrant') !== 'Thanks for the work you do!' && (
                    <Fields.Text
                      label={purposeOfGrantNoteLabel}
                      name="purposeOfGrantNote"
                      error={!!errors?.purposeOfGrantNote}
                      helperText={errors?.purposeOfGrantNote?.message}
                      {...register('purposeOfGrantNote')}
                    />
                  )}
                </Stack>
                <Text.Bold>Self-attestation *</Text.Bold>
                <FormControl error={!!errors?.selfAttestation}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: errors?.selfAttestation ? palette.red : 'black',
                        }}
                        {...register('selfAttestation', { ...validations.required })}
                        checked={getValues('selfAttestation')}
                        onChange={(e) => {
                          setValue('selfAttestation', e.target.checked, { shouldValidate: true })
                        }}
                      />
                    }
                    label="I certify that my gift is for a permitted purpose
							  and will not result in a more than incidental benefit to me or persons related to me."
                  />
                  {errors?.selfAttestation && <FormHelperText>{errors?.selfAttestation?.message}</FormHelperText>}
                </FormControl>
                <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
              </Stack>
            </ContentBox>

            <Row spacing={2} justifyContent="flex-end">
              {!isLoggedIn && (
                <Button type="submit" variant="contained" color="primary" onClick={() => setValue('isGuest', true)}>
                  Continue As Guest
                </Button>
              )}

              <Button type="submit" variant="contained" color="secondary" onClick={() => setValue('isGuest', false)}>
                {isLoggedIn ? 'Next' : 'Create An Account'}
              </Button>
            </Row>
          </Stack>
        </fieldset>
      </form>

      {loginDialog}
    </Container>
  )
}
