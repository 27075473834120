import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { Box } from '@mui/material'
import { useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import {
  Button,
  Container,
  ContentBox,
  Fields,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Row,
  Select,
  Stack,
  Switch,
  Text,
  useAlert,
  validations,
} from '../../components'
import { CORPORATION_USER_TYPE, COUNTRY_LIST, INDIVIDUAL_USER_TYPE, PROVINCES_LIST } from '../../constants'
import { mutations, queries } from '../../graphql'
import { parseAndSetFormErrors } from '../../utils'

export function DonorInfoStep({ isLoggedIn, me, stepperState, dispatchStep }) {
  const client = useApolloClient()
  const location = useLocation()

  const { profile, email: primaryEmail } = me ?? {}

  const [{ Alert, alertProps }, { setAlert }] = useAlert()

  const captchaRef = useRef()
  const [captchaToken, setCaptchaToken] = useState()
  const { data: { captcha: captchaSiteKey } = {}, refetch: refetchCaptcha } = useQuery(gql`
    query Captcha {
      captcha
    }
  `)

  const defaultValues = useMemo(() => {
    if (isLoggedIn) {
      const p = profile
      return {
        firstName: p?.firstName ?? '',
        middleInitials: p?.middleInitials ?? '',
        lastName: p?.lastName ?? '',
        phone: p?.phone ?? '',
        corporateName: p?.corporateName ?? '',
        address: {
          lineOne: p?.address?.lineOne ?? '',
          lineTwo: p?.address?.lineTwo ?? '',
          city: p?.address?.city ?? '',
          province: p?.address?.province ?? '',
          postalCode: p?.address?.postalCode ?? '',
          country: p?.address?.country ?? 'Canada',
        },
      }
    }

    const di = stepperState?.donorInfo
    return {
      firstName: di?.firstName ?? '',
      middleInitials: di?.middleInitials ?? '',
      lastName: di?.lastName ?? '',
      phone: di?.phone ?? '',
      corporateName: di?.corporateName ?? '',
      address: {
        lineOne: di?.address?.lineOne ?? '',
        lineTwo: di?.address?.lineTwo ?? '',
        city: di?.address?.city ?? '',
        province: di?.address?.province ?? '',
        postalCode: di?.address?.postalCode ?? '',
        country: di?.address?.country ?? 'Canada',
      },
    }
  }, [isLoggedIn, profile, stepperState?.donorInfo])
  const { register, handleSubmit, formState, getValues, setValue, setError, watch, control } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues,
  })
  const { isDirty, isSubmitting, errors = {} } = formState

  const [signUp] = useMutation(mutations.user.signUp, {
    onError: async (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)

      /* if we submitted a captcha, the server likely verified it and it can't
       * be used again; so reset the widget ... */
      captchaRef?.current?.resetCaptcha?.()

      if (newErrors?.message.includes('Email taken')) {
        setAlert({ message: 'An account with this email address already exists.', severity: 'error' })
      } else if (newErrors?.message === 'captcha required') {
        setAlert({ message: 'Please fill out the captcha on this form.', severity: 'error' })
        /* if we a captcha was not required before, it is now */
        if (!captchaSiteKey) await refetchCaptcha()
      } else {
        setAlert({ message: 'Signup Failed', severity: 'error' })
      }
    },
  })

  const { data: existingGuest } = useQuery(queries.user.getUserByEmail, {
    variables: {
      email: stepperState.email,
    },
  })

  const [guestSignUp] = useMutation(mutations.user.guestSignUp, {
    onCompleted: async () => {
      console.log('GuestSignUp completed.')
    },
    onError: async (newErrors) => {
      parseAndSetFormErrors(newErrors, setError)
      console.warn('GuestSignup Failed:', newErrors)
    },
  })

  const getOrCreateGuest = async (payload) => {
    if (existingGuest) {
      if (existingGuest?.getUserByEmail?.status === 1) {
        return existingGuest?.getUserByEmail
        // eslint-disable-next-line no-else-return
      } else {
        // email belongs to a non-guest user -> should we prompt them to login or allow continue as guest? what if not that user
        parseAndSetFormErrors('This email belongs to a non-guest user. Please login.', setError)
        console.log('This email belongs to a non-guest user')
      }
    }

    const newGuest = await guestSignUp({
      variables: payload,
    })
    return newGuest.data?.guestSignUp
  }

  // @fixme This should be part of the form state
  const [isCorporationEditable, setIsCorporation] = useState(false)
  const isCorporation = isLoggedIn ? profile?.userType === CORPORATION_USER_TYPE : isCorporationEditable

  const onSubmit = async ({
    firstName,
    middleInitials,
    lastName,
    password,
    passwordConfirmation,
    phone,
    corporateName,
    address,
  }) => {
    if (isLoggedIn) {
      if (isDirty) {
        // eslint-disable-next-line no-console
        console.info(`Donor information has been edited, updating profile of ${me?.id}`)

        await client.mutate({
          mutation: mutations.user.updateProfile,
          variables: {
            data: {
              firstName,
              middleInitials,
              lastName,
              phone,
              corporateName,
              address,
            },
          },
          refetchQueries: [{ query: queries.user.me }],
          awaitRefetchQueries: true,
        })
      }

      dispatchStep({ action: 'NEXT' })
    } else {
      localStorage.setItem(
        'guest_user_prefill_info',
        JSON.stringify({
          firstName,
          middleInitials,
          lastName,
          email: stepperState.email,
          phone,
          corporateName,
          address,
        })
      )

      if (stepperState.isGuest) {
        const user = await getOrCreateGuest({
          data: {
            firstName,
            middleInitials,
            lastName,
            corporateName,
            email: stepperState.email,
            phone,
            address,
            userType: isCorporation ? CORPORATION_USER_TYPE : INDIVIDUAL_USER_TYPE,
            afterLogin: location.pathname,
          },
        })

        if (!user) {
          return
        }

        dispatchStep({
          action: 'NEXT',
          state: { fundId: user?.funds[0]?.id, user },
        })
      } else {
        await signUp({
          variables: {
            data: {
              firstName,
              middleInitials,
              lastName,
              corporateName,
              email: stepperState.email,
              password: password || '',
              passwordConfirmation: passwordConfirmation || '',
              phone,
              address,
              userType: isCorporation ? CORPORATION_USER_TYPE : INDIVIDUAL_USER_TYPE,
              afterLogin: location.pathname,
              captcha: captchaToken || '',
            },
          },
        })

        dispatchStep({
          modal: {
            type: 'LOGIN_MODAL',
            params: {
              prefillEmail: stepperState.email,
            },
          },
        })
      }
    }
  }

  return (
    <Container maxWidth="md" sx={{ py: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={isSubmitting} style={{ display: 'contents', border: 0, p: 0, m: 0 }}>
          <Stack spacing={2}>
            <ContentBox border>
              <Stack spacing={2}>
                {!isLoggedIn && (
                  <FormControlLabel
                    size="small"
                    control={<Switch />}
                    checked={isCorporation}
                    onChange={(e) => setIsCorporation(e.target.checked)}
                    label="Sign up as a corporation or an organization"
                    sx={{ mb: 2 }}
                  />
                )}
                <Stack alignItems="flex-start">
                  {isCorporation && (
                    <>
                      <Fields.Text
                        inputProps={{ 'data-testid': 'corporateName' }}
                        label="Corporate Name *"
                        error={!!errors.corporateName}
                        helperText={errors.corporateName?.message}
                        {...register('corporateName', { ...validations.required })}
                      />
                      {!isLoggedIn && !stepperState.isGuest && (
                        <>
                          <input
                            style={{ display: 'none' }}
                            aria-hidden="true"
                            name="email"
                            autoComplete="username"
                            readOnly
                            value={stepperState.email}
                            required
                          />
                          <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
                        </>
                      )}
                      <Text.Bold>Primary Contact Person</Text.Bold>
                    </>
                  )}
                  <Fields.Text
                    inputProps={{ 'data-testid': 'firstName' }}
                    label="First Name *"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    {...register('firstName', { ...validations.required })}
                  />
                  <Fields.Text
                    inputProps={{ 'data-testid': 'middleInitial' }}
                    label="Middle Initials"
                    error={!!errors.middleInitial}
                    helperText={errors.middleInitial?.message}
                    {...register('middleInitials')}
                  />
                  <Fields.Text
                    inputProps={{ 'data-testid': 'lastName' }}
                    label="Last Name *"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    {...register('lastName', { ...validations.required })}
                  />
                  {!isCorporation && !isLoggedIn && !stepperState.isGuest && (
                    <>
                      <input
                        style={{ display: 'none' }}
                        aria-hidden="true"
                        name="email"
                        autoComplete="username"
                        readOnly
                        value={stepperState.email}
                        required
                      />
                      <Fields.NewPassword register={register} errors={errors} getValues={getValues} />
                    </>
                  )}

                  <Text.Bold>Contact</Text.Bold>
                  <Fields.Text
                    inputProps={{ 'data-testid': 'phoneNumber' }}
                    type="text"
                    label="Phone Number"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    {...register('phone', { ...validations.phoneNumber })}
                  />

                  <Text.Bold>Your Address</Text.Bold>
                  <Text.Body>
                    Address is used for tax receipts only. No physical mail will be sent by GiveWise.
                  </Text.Body>
                  <Fields.Text
                    label="Address Line One"
                    required
                    error={!!errors.address?.lineOne}
                    helperText={errors?.address?.lineOne?.message}
                    {...register('address.lineOne', { ...validations.required })}
                  />
                  <Fields.Text
                    label="Address Line Two"
                    error={!!errors.address?.lineTwo}
                    helperText={errors?.address?.lineTwo?.message}
                    {...register('address.lineTwo')}
                  />
                  <Fields.Text
                    label="City"
                    required
                    error={!!errors.address?.city}
                    helperText={errors?.address?.city?.message}
                    {...register('address.city', { ...validations.required })}
                  />

                  {getValues('address.country') === 'Canada' ? (
                    <FormControl fullWidth>
                      <InputLabel id="provinceLabel">Province *</InputLabel>
                      <Controller
                        control={control}
                        name="address.province"
                        render={({ field: { ref, ...field } }) => (
                          <Select
                            {...field}
                            inputProps={{ 'data-testid': 'province', ref }}
                            label="Province *"
                            labelId="provinceLabel"
                            aria-label="Province *"
                            error={!!errors.address?.province}
                            helpertext={errors?.address?.province?.message}
                            required
                          >
                            {PROVINCES_LIST.map((p) => (
                              <MenuItem key={p} value={p}>
                                {p}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  ) : (
                    <Controller
                      control={control}
                      name="address.province"
                      render={({ field: { ref, ...field } }) => (
                        <Fields.Text
                          {...field}
                          inputProps={{ 'data-testid': 'province', ref }}
                          label="Province/State"
                          value={watch('address.province')}
                          required
                          error={!!errors.address?.province}
                          helperText={errors?.address?.province?.message}
                        />
                      )}
                    />
                  )}
                  <Fields.Text
                    label="Postal Code"
                    required
                    error={!!errors.address?.postalCode}
                    helperText={errors?.address?.postalCode?.message}
                    {...register('address.postalCode', { ...validations.required })}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="countryLabel">Country *</InputLabel>
                    <Controller
                      name="address.country"
                      control={control}
                      render={({ field: { ref, ...field } }) => (
                        <Select
                          {...field}
                          inputProps={{ 'data-testid': 'country', ref }}
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('address.province', '', { shouldValidate: true })
                          }}
                          label="Country *"
                          labelId="countryLabel"
                          aria-label="Country *"
                          error={!!errors.address?.country}
                          helpertext={errors?.address?.country?.message}
                        >
                          {COUNTRY_LIST.map((c) => (
                            <MenuItem key={c} value={c}>
                              {c}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>

                  {!isLoggedIn && (
                    <>
                      {captchaSiteKey && (
                        <HCaptcha sitekey={captchaSiteKey} onVerify={setCaptchaToken} ref={captchaRef} />
                      )}
                      <Row justifyContent="flex-end" spacing={4}>
                        <Text.Body variant="body2" sx={{ textAlign: 'right' }}>
                          By clicking &ldquo;Sign Up&rdquo;, you agree to our{' '}
                          <a
                            href="https://www.givewise.ca/terms-conditions"
                            target="_blank"
                            rel="noreferrer"
                            data-testid="termConditionLink"
                          >
                            Terms and Conditions
                          </a>{' '}
                          and{' '}
                          <a
                            href="https://www.givewise.ca/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                            data-testid="termConditionLink"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Text.Body>
                        {errors.form && <FormHelperText error={!!errors.form}>{errors.form.message}</FormHelperText>}
                      </Row>
                    </>
                  )}
                </Stack>

                <Alert {...alertProps} sx={{ alignSelf: 'flex-end' }} />
              </Stack>
            </ContentBox>

            <Stack direction="row" spacing={2}>
              <Button type="button" onClick={() => dispatchStep({ action: 'PREV' })} disabled={isSubmitting}>
                Back
              </Button>
              <Box flexGrow={1} />
              <Button type="submit" disabled={isSubmitting}>
                {isLoggedIn || stepperState.isGuest ? 'Next' : 'Sign Up'}
              </Button>
            </Stack>
          </Stack>
        </fieldset>
      </form>
    </Container>
  )
}
