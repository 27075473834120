import { List, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import invariant from 'invariant'

import { Icons, RadioListItem } from '../../components'
import { toAmountString } from '../../utils'
import { PaymentMethodsMeta, paymentMethods } from './payment'
import { GuestPaymentMethodsMeta, guestPaymentMethods } from './payment-guest/index'

export function PaymentMethodRadioList({
  control,
  setValue,
  fund,
  hideRadio,
  filterAmountType,
  required = false,
  isGuest,
}) {
  const paymentMethodsToUse = isGuest ? guestPaymentMethods : paymentMethods

  const listRef = useRef()
  useEffect(() => {
    /** @type {HTMLUListElement|undefined} */
    const listEl = listRef.current
    invariant(listEl, 'listRef.current not defined')
    const checkedRadio = listEl.querySelector('input[type="radio"]:checked')
    if (checkedRadio) {
      // When a radio element is checked on mount, auto-scroll to the active list tiem
      const listItemEl = checkedRadio.closest('label')
      listEl.scrollTo({
        behavior: 'instant',
        top: Math.max(listItemEl.offsetTop + listItemEl.clientHeight - listEl.clientHeight, 0),
      })
    }
  }, [])

  return (
    <>
      {required ? (
        <Typography component="p" variant="h6">
          Choose a payment method for this donation: *
        </Typography>
      ) : (
        <Typography component="p" variant="h6">
          You can make your donation via...
        </Typography>
      )}
      <List
        ref={listRef}
        sx={{
          width: '100%',
          mb: 2,
          maxHeight: 200,
          overflowY: 'auto',
          borderBottom: '1px solid',
          borderBottomColor: 'divider',
        }}
      >
        {fund && (
          <RadioListItem
            control={control}
            name="paymentMethodType"
            value="wallet"
            hideRadio={hideRadio}
            beforeChange={() => {
              setValue('paymentMethodId', '')
            }}
            icon={<Icons.Wallet />}
            label="Wallet"
            helperText={`Use up to ${toAmountString(fund.givingWalletBalance ?? 0)} from the "${
              fund.name
            }" fund's balance`}
            required={required}
          />
        )}
        {paymentMethodsToUse.map((paymentMethod) => (
          <RadioListItem
            key={paymentMethod.value}
            control={control}
            name="paymentMethodType"
            value={paymentMethod.value}
            hideRadio={hideRadio}
            beforeChange={() => {
              setValue('paymentMethodId', '')
            }}
            icon={paymentMethod.icon}
            label={paymentMethod.label}
            helperText={paymentMethod.helperText}
            required={required}
          />
        ))}
      </List>
    </>
  )
}
