import { Icons } from '../../../components'
import * as CreditCard from './credit-card-guest'

export const GUEST_PAYMENT_METHOD_TYPE = {
  creditCard: 'credit-card',
}

export const GuestPaymentMethodsMeta = {
  [GUEST_PAYMENT_METHOD_TYPE.creditCard]: {
    icon: <Icons.CreditCard />,
    label: 'Credit Card',
    helperText: 'Standard credit card fees will apply.',

    paymentClearingType: 'sync',
    supportsRecurring: false,
    supportsScheduled: false,
    PaymentUI: CreditCard.PaymentUI,
    getPaymentValues: CreditCard.getPaymentValues,
    createContributions: CreditCard.createContributions,
  },
}

export const guestPaymentMethods = [
  {
    value: GUEST_PAYMENT_METHOD_TYPE.creditCard,
    ...GuestPaymentMethodsMeta[GUEST_PAYMENT_METHOD_TYPE.creditCard],
  },
]
